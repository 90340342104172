import { SET_LANGUAGE, SET_SHOW_TOP_BTN } from './types';
import {KEYS, getStorageKey, setStorageKey} from '../../utils/storage';
import { setLanguage } from '../../localisations';

export const initLanguage = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let lang = getStorageKey(KEYS.LANGUAGE);
        await setLanguage(lang ?? 'en');
        dispatch({
            type: SET_LANGUAGE, payload: lang ?? 'en'
        });
        resolve();
    });
};

export const initCountry = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let countryCode = getStorageKey(KEYS.COUNTRY_CODE);
        setStorageKey(KEYS.COUNTRY_CODE, countryCode ?? 'US');

        // TODO: Do we need to set default language based on country
        // dispatch({
        //     type: SET_LANGUAGE, payload: countryCode ?? 'US'
        // });
        resolve();
    });
};

export const changeLanguage = (lang) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        await setLanguage(lang ?? 'en');
        dispatch({
            type: SET_LANGUAGE, payload: lang ?? 'en'
        });
        resolve();
    });
};

export const setShowTopBtn = (payload) => ({
    type: SET_SHOW_TOP_BTN, payload
})