import React, { useEffect } from 'react';
import ImgLogoDark from '../../assets/images/logo-black.jpg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Splash = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((state) => state.auth);

    useEffect(() => {
        setTimeout(() => {
            if (isLoggedIn) {
                navigate('/app');
              }
              else {
                navigate('/login')
              }
        }, 3000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isLoggedIn]);
      
    return (
        <div className='w-full h-full p-10 bg-[#000] flex justify-center items-center'>
            <img src={ImgLogoDark} className="w-[275px] object-contain"/>
        </div>
    )
}

export default Splash;