import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty } from '../../utils/common';

const Login = () => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState('')
    const onSubmit = () => {
        if (isEmpty(phone)) {
            return;
        }
        navigate('/verify-code', { state: { phone: phone } })
    }

    return (
        <div className='w-full h-full p-10 bg-primary1 flex flex-col justify-center items-center'>
            <h1 className='text-[18px] text-white font-bold'>Join us via phone number</h1>
            <p className='mt-3 text-[12px] text-[#999] font-medium'>We'll send a code to your WHATSAPP</p>
            <input className='px-2 mt-10 w-full h-[42px] rounded-[4px]' placeholder='Phone number' value={phone} onChange={(e) => setPhone(e.target.value)} />
            <button className='mt-9 w-full h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold' onClick={onSubmit}>Next</button>
            <p className='mt-8 text-[12px] text-[#999] font-medium text-center'>Joining our app means you agree with our <Link className=' underline'>Terms of Use</Link> and <Link className=' underline'>Privacy Policy</Link></p>
        </div>
    )
}

export default Login;