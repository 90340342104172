import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import AuthMiddleware from "../authMiddleware.js";
import AuthedLayout from "../layouts/AuthedLayout.js";
import GuestLayout from "../layouts/GuestLayout.js";
import Splash from "../../views/splash/Splash.js";
import Login from "../../views/auth/Login.js";
import VerifyCode from "../../views/auth/VerifyCode.js";
import InitLocation from "../../views/onboard/InitLocation.js";
import Mode from "../../views/onboard/Mode.js";
import UserName from "../../views/onboard/UserName.js";

const AppRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/"
          element={<GuestLayout />}
        >
          <Route exact path={''} element={<Splash />} />
          <Route exact path={'login'} element={<Login />} />
          <Route exact path={'verify-code'} element={<VerifyCode />} />
        </Route>
        <Route path="/app" element={<AuthMiddleware><AuthedLayout /></AuthMiddleware>}>
          <Route exact path={"onboard/location"}  element={<InitLocation />} />
          <Route exact  path={"onboard/mode"} element={<Mode />} />
          <Route exact  path={"onboard/username"} element={<UserName />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AppRoutes;
