import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';

const VerifyCode = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [code, setCode] = useState('');
    const [time, setTime] = useState(59);
    let timer = useRef(null);
    useEffect(() => {
        startTimer();
    }, [])

    useEffect(() => {
        if (time == 0) {
            clearInterval(timer.current)
            setCode('')
        }
    }, [timer, time])

    useEffect(() => {
        if (code.length == 5) {
            onVerify(code)
        }
    }, [code])

    const startTimer = () => {
        timer = setInterval(() => {
            setTime(pre => pre >= 1 ? pre - 1 : 0)
        }, 1000);
    }

    const onVerify = (code) => {
        if (code == '33333'){
            navigate('/app/onboard/location')
        }
    }

    const onResend = () => {
        setCode('')
        startTimer();
    }

    return (
        <div className='w-full h-full py-5 bg-primary1 flex flex-col justify-start items-center'>
            <Header />
            <div className='w-full px-5 flex flex-col justify-start items-center'>
                <h1 className='mt-10 text-[18px] text-white font-bold'>Enter the code</h1>
                <p className='mt-3 text-center text-[12px] text-[#999] font-medium'>We have sent you a verification code to <br />{state?.phone} Whatsapp</p>
                <input className='mt-10 w-full h-[42px] rounded-[4px] text-center' value={code} onChange={(e) => {
                    if (e.target.value.length <= 5) {
                        setCode(e.target.value)
                    }
                }} />
                {
                    time > 0 ?
                        <p className='mt-9 text-[12px] text-[#666] font-medium'>You can resend code in {time} seconds</p>
                        :
                        <button className='mt-9 w-full h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold' onClick={onResend}>Request code again</button>
                }
            </div>
        </div>
    )
}

export default VerifyCode;