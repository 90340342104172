import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty } from '../../utils/common';
import ImgCustomer from '../../assets/images/customer.png';
import Header from '../../components/Header/Header';

const Mode = () => {
    const navigate = useNavigate();

    const onCustomer = () => {
        navigate('/app/onboard/username', { state: { mode: 'customer' } })
    }

    const onHere = () => {
        navigate('/app/onboard/username', { state: { mode: 'hero' } })
    }

    return (
        <div className='w-full h-full py-5 bg-primary1 flex flex-col justify-center items-center'>
            <Header />
            <h1 className='mt-10 text-[18px] text-white font-bold text-center'>Are you a customer or a hero?</h1>
                <p className='mt-3 text-center text-[12px] text-[#999] font-medium'>You can change the mode later</p>
            <div className='w-full flex-1 flex flex-col justify-center items-center pt-5 pb-10'>
                <img src={ImgCustomer} className='mt- w-[150px] object-contain' />
            </div>
            <div className='w-full px-5'>
                <button className='mt-9 w-full h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold' onClick={onCustomer}>Customer</button>
                <button className='mt-3 w-full h-[42px] rounded-[4px] bg-[#999] text-white text-[14px] font-semibold' onClick={onHere}>Hero</button>
            </div>
        </div>
    )
}

export default Mode;