import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isEmpty } from '../../utils/common';
import ImgMap from '../../assets/images/gmap.png';

const InitLocation = () => {
    const navigate = useNavigate();

    const onSubmit = () => {
        navigate('/app/onboard/mode')
    }

    const onOtherLocation = () => {

    }

    return (
        <div className='w-full h-full py-5 bg-primary1 flex flex-col justify-center items-center'>
            <div className='w-full flex-1 flex flex-col justify-center items-center pt-5 pb-10'>
                <img src={ImgMap} className='w-[150px] object-contain' />
                <h1 className='mt-10 text-[18px] text-white font-bold'>Are you in Kuala Lumpur?</h1>
            </div>
            <div className='w-full px-5'>
                <button className='mt-9 w-full h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold' onClick={onSubmit}>Yes, I'm here</button>
                <button className='mt-3 w-full h-[42px] rounded-[4px] bg-[#999] text-white text-[14px] font-semibold' onClick={onOtherLocation}>No</button>
            </div>
        </div>
    )
}

export default InitLocation;