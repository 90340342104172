import React, { useEffect, useState, Suspense } from "react"; 
import "./index.css";
import AppRoutes from "./routes/appRoutes"; 
import { useDispatch, useSelector } from "react-redux";
import { initCountry, initLanguage } from "../redux/actions/app";
import { useLocation, useNavigate } from "react-router-dom";
import RouteNames from "../constants/route_names";
import { loadLoginData } from "../redux/actions/auth";
import axios from "axios";
import { KEYS, setStorageKey } from "../utils/storage"; 

const App = () => {
  const dispatch = useDispatch(); 
  const [appLoaded, setAppLoaded] = useState(false); 
 
  useEffect(() => {
    getGeoInfo();
    loadSettings();
  }, []);

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then(async (response) => {
        let data = response.data;
        setStorageKey(KEYS.COUNTRY_CODE, data.country);
      })
      .catch((error) => {
        setStorageKey(KEYS.COUNTRY_CODE, "US");

        // throw new Error("Error with getting country code based on ip");
      });
  };

  const loadSettings = async () => {
    try {
      await dispatch(initLanguage());
      await dispatch(initCountry());
      await dispatch(loadLoginData());
      setAppLoaded(true);
    } catch (error) {}
  };

  if (!appLoaded) {
    return null;
  }
 
  return (
    <AppRoutes />
  );
};

export default App;
