import { APP_SET_LOGOUT, APP_SET_LOGIN_USER } from '../actions/types';

const initialState = {
    isLoggedIn: false,
    user: null,
    isAffilate: false,
    access_token: null
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case APP_SET_LOGOUT:
            return { ...state, isLoggedIn: false, user: null, isAffilate: false,  access_token: null};
        case APP_SET_LOGIN_USER:
            return { ...state, isLoggedIn: true, isAffilate: action.payload?.isAffilate == true, user: action.payload?.user,  access_token: action.payload?.access_token};
        default:
            return state;
    }
};

export default auth;
