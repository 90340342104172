import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { isEmpty } from '../../utils/common';

const UserName = () => {
    const navigate = useNavigate();
    const [username, setUserName] = useState('')
    const onSubmit = () => {
        if (isEmpty(username)) {
            return;
        }
        navigate('/verify-code', { state: { username: username } })
    }

    return (
        <div className='w-full h-full py-5 bg-primary1 flex flex-col justify-center items-center'>
            <Header />
            <div className='mt-10 flex-1 w-full flex flex-col px-5 justify-start items-center'>
                <h1 className='text-[18px] text-white font-bold'>Welcome to HeroMay</h1>
                <p className='mt-3 text-[12px] text-[#999] font-medium'>Please introduce yourself</p>
                <input className='px-2 mt-9 w-full h-[42px] rounded-[4px]' placeholder='Username' value={username} onChange={(e) => setUserName(e.target.value)} />
                <button className='mt-7 w-full h-[42px] rounded-[4px] bg-primary2 text-white text-[14px] font-semibold' onClick={onSubmit}>Next</button>
            </div>
        </div>
    )
}

export default UserName;