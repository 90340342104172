import { createUUID } from './common';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const KEYS = {
  APP_TOKEN: 'HEROMAY_APP_TOKEN',
  USER_DATA: 'HEROMAY_USER_DATA',
  LANGUAGE: 'HEROMAY_LANGUAGE',
  COUNTRY_CODE: 'HEROMAY_COUNTRY_CODE',
  DEVICE_UNIQUE_ID: 'HEROMAY_DEVICE_UNIQUE_ID',
};

export const setAppToken = (token) => {
  let d = new Date();
  d.setTime(d.getTime() + 6 * 30 * 24 * 60 * 60 * 1000); // 6 months expiration
  cookies.set(KEYS.APP_TOKEN, token, { path: '/', expires: d });
};

export const clearAppToken = () => {
  let d = new Date('1970-01-01');
  cookies.remove(KEYS.APP_TOKEN, { path: '/', expires: d });
};

export const getAppToken = () => {
  return cookies.get(KEYS.APP_TOKEN);
};

export const getStorageKey = (key) => {
  let data = localStorage.getItem(key);
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      // Handle the case where data is not valid JSON
      // You can return 'null' or some default value
      console.error("Error parsing JSON for key:", key, e);
      return null;
    }
  }
  return null;
};


export const setStorageKey = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const removeStorageKey = (key) => {
  localStorage.removeItem(key);
};

export const getDeviceUniqueId = () => {
  let uniqueId = localStorage.getItem(KEYS.DEVICE_UNIQUE_ID);
  if (uniqueId == null) {
    uniqueId = createUUID();
    localStorage.setItem(KEYS.DEVICE_UNIQUE_ID, uniqueId);
  }
  return uniqueId;
};